<script>
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { pipe } from 'fp-ts/function'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    directives: {
      Ripple,
    },
    props: {
      token: {
        type: String,
        default: null,
      },
    },
    components: {
      BCardCode,
      Cleave,
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        cpf: undefined,
        codigo: '',
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
        },
        assinar: 'pendente',
      }
    },
    methods: {
      isCPFValid(cpf) {
        if (cpf == undefined) return false
        cpf = cpf.replace(/[^\d]+/g, '')
        if (cpf == '') return false
        // Elimina CPFs invalidos conhecidos
        if (
          cpf.length != 11 ||
          cpf == '00000000000' ||
          cpf == '11111111111' ||
          cpf == '22222222222' ||
          cpf == '33333333333' ||
          cpf == '44444444444' ||
          cpf == '55555555555' ||
          cpf == '66666666666' ||
          cpf == '77777777777' ||
          cpf == '88888888888' ||
          cpf == '99999999999'
        )
          return false
        // Valida 1o digito
        // TODO: Refatorar esse código, muito feio
        var add = 0
        for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
        var rev = 11 - (add % 11)
        if (rev == 10 || rev == 11) rev = 0
        if (rev != parseInt(cpf.charAt(9))) return false
        // Valida 2o digito
        add = 0
        for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
        rev = 11 - (add % 11)
        if (rev == 10 || rev == 11) rev = 0
        if (rev != parseInt(cpf.charAt(10))) return false
        return true
      },
      assinaturas: function () {
        return {
          assinar: async (cpf, token) => {
            const { status, message, assinar } = (
              await useJwt.post('financeiro-assinatura/receber', {
                codigo: this.codigo,
                cpf: this.cpf,
              })
            ).data
            if (status == 200) {
              this.toast().success(message)
              this.assinar = 'assinado'
            } else {
              if (assinar == 'reassinado') {
                this.toast().info('Contrato já foi assinado.')
                this.assinar = 'reassinado'
              } else {
                this.toast().error(message)
              }
            }
          },
        }
      },
      toast: function () {
        return {
          success: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
          error: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
          info: async (message) => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'info',
                },
              },
              {
                position: 'top-right',
                timeout: 3000,
              },
            )
          },
        }
      },
    },
  }
</script>

<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-align-center tw-h-screen px-2">
    <b-card-code>
      <b-card-body>
        <b-row>
          <b-col md="12">
            <h4 class="mb-1 tw-text-center tw-flex tw-items-center tw-flex-col">
              <feather-icon icon="FileTextIcon" size="55" class="tw-text-center mb-2" />
              Assinatura de Contrato
            </h4>
            <b-form-group label-for="cpfResponsavel">
              <validation-provider #default="{ errors }" name="CPF do responsável" rules="required">
                <label class="tw-text-gray-600 tw-text-center" for="cpfResponsavel">Insira seu CPF</label>
                <Cleave
                  id="cpfResponsavel"
                  type="text"
                  placeholder="CPF"
                  v-model="cpf"
                  :raw="false"
                  :disabled="assinar == 'assinado' || assinar == 'reassinado'"
                  :options="options.cleaveCPF"
                  class="form-control"
                  @blur="isCPFValid(cpf)"
                />
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="codigoResponsavel">
              <validation-provider #default="{ errors }" name="Código" rules="required">
                <label class="tw-text-gray-600 tw-text-center" for="codigoResponsavel">Insira o código de verificação</label>
                <b-form-input
                  id="codigoResponsavel"
                  placeholder="Código de Verificação"
                  v-model="codigo"
                  :disabled="assinar == 'assinado' || assinar == 'reassinado'"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <transition name="fade" mode="out-in">
            <b-col key="1" v-if="assinar == 'pendente'" md="12" class="tw-flex">
              <b-button
                class="tw-flex-grow"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="!isCPFValid(cpf) || codigo.length < 6"
                @click="assinaturas().assinar(cpf, token)"
              >
                Assinar Contrato
              </b-button>
            </b-col>
            <b-col key="2" v-else md="12" class="tw-flex">
              <b-button
                class="tw-flex-grow"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="assinar == 'assinado' ? 'success' : assinar == 'reassinado' ? 'info' : 'success'"
              >
                Contrato Assinado
              </b-button>
            </b-col>
          </transition>
        </b-row>
      </b-card-body>
    </b-card-code>
  </div>
</template>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
